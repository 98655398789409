<template>
    <div class="chartBox">
        <div class=" flexCenter" style="height: 60px; margin-top: 10px; display: flex; ">
            <img src="/img/home_top_title.png" alt="" style="height:40px">
            <!-- <img src="/img/home_top_1.png" alt="" style="height:40px">
            <div style="width: 400px; text-align: center;font-weight: bold;font-size: 20px;">
                南京市中等职业学校毕业生就业管理平台
            </div>
            <img src="/img/home_top_2.png" alt="" style="height:40px"> -->
        </div>

        <div class=" flexBetween" style="height: 40px; margin-top: 0px; display: flex; padding: 0 27px;">
            <div class="flexStart" style="height: 100%;">
                <div class="flexCenter" @click="goPage"
                    style="font-size:18px; height: 100%;background-color:#5975FF;color:#fff;border-radius:5px;padding:0 32px;white-space:nowrap;">
                    <i class="el-icon-s-home mr10" style="font-size:20px;"></i>
                    返回
                </div>
                <div class="ml20" style="position: relative; height: 100%;">
                    <div class="flexCenter"
                        style="height: 100%;border-radius:5px;padding:0 32px;white-space:nowrap;border:1px solid #878787;">
                        {{ ckdYear }} 年度
                    </div>
                    <div style="position: absolute; height: 100%; top: 0;">
                        <el-select size="middle" style="opacity: 0; height: 100%;" v-model="ckdYear">
                            <el-option :label="y" :value="y" v-for="(y, i) in years" :key="i"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="flexStart" style="height: 100%;">
                <div>
                    毕业生总数：<span id="bycount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                </div>
                <div style="margin-left:50px;">
                    总就业人数：<span id="jycount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                </div>
                <div style="margin-left:50px;">
                    待业人数：<span id="dycount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                </div>
            </div>
        </div>


        <div v-if="false" style="height: 60px; padding-top: 10px; display: flex; ">
            <div style="width: 30%;">
                <dv-decoration-10 style="width:90%;height:5px;" />
            </div>
            <div style="width: 40%; display: flex; transform: translateY(2px);">
                <div style="width: 20%;">
                    <dv-decoration-8 style="width:100%;height:30px;transform: translateX(-60px);" />
                </div>
                <div style="width:60%">
                    <dv-decoration-7 style="height:45px;">
                        <div style="width: 400px; text-align: center; color: #fff;font-weight: bold;font-size: 20px;">
                            南京市中等职业学校毕业生就业管理平台
                        </div>
                    </dv-decoration-7>

                    <dv-decoration-6 style="width:50%;height:10px; margin: 0 auto;" />
                </div>
                <div style="width: 20%;">
                    <dv-decoration-8 :reverse="true" style="width:100%;height:30px;transform: translateX(60px);" />
                </div>


            </div>
            <div style="width: 30%;transform: rotateY(180deg);">
                <dv-decoration-10 style="width:90%;height:5px;" />
            </div>
        </div>

        <div v-if="false" style="height: 60px; margin-top: -20px; display: flex; padding: 0 30px; color: #fff;">
            <div style="width: 12%;">
                <dv-decoration-11 style="height:60px; cursor: pointer;">
                    <div @click="goPage"
                        style=" display: flex; height: 100%; align-items: center; justify-content: center;">
                        分类数据
                    </div>
                </dv-decoration-11>
            </div>
            <div style="width: 12%;">
                <dv-decoration-11 style="height:60px;">
                    <div style="position: relative;width: 100%; height: 100%;">
                        <div style=" display: flex; height: 100%; align-items: center; justify-content: center;">
                            {{ ckdYear }}
                        </div>
                        <div style="position: absolute; width: 100%; height: 100%; top: 0;">
                            <el-select size="small" style="opacity: 0;" v-model="ckdYear">
                                <el-option :label="y" :value="y" v-for="(y, i) in years" :key="i"></el-option>
                            </el-select>
                        </div>
                    </div>

                </dv-decoration-11>
            </div>
            <div style="width: 52%;"></div>
            <div style="width: 12%;">
                <dv-decoration-11 style="height:60px;">毕业生总数：<span id="bycount">***</span></dv-decoration-11>
            </div>
            <div style="width: 12%;">
                <dv-decoration-11 style="height:60px;">总就业人数：<span id="jycount">***</span></dv-decoration-11>
            </div>
            <div style="width: 12%;">
                <dv-decoration-11 style="height:60px;">待业人数：<span id="dycount">***</span></dv-decoration-11>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {
            years: [],
            ckdYear: parseInt(sessionStorage.getItem("year") || new Date().getFullYear())
        };
    },
    mounted() {
        let now = new Date().getFullYear();
        for (let i = now; i >= 2023; i--) {
            this.years.push(i);
        }
        if (!this.ckdYear) {
            this.ckdYear = now
        }

    },

    methods: {
        goPage() {

            this.$router.push("/tjmingce")
        }
    },
    beforeDestroy: function () {

    }

};
</script>

<style scope lang="less" type="text/less">
.chartBox {
    width: 100%;
}
</style>
